@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$primary: mat.define-palette(mat.$indigo-palette);
$accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$warn: mat.define-palette(mat.$red-palette);
$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-legacy-component-themes($theme);

// Our dark theme
.dark-theme {
    color: white;
    $dark-primary: mat.define-palette(mat.$yellow-palette);
    $dark-accent: mat.define-palette(mat.$amber-palette, A400, A100, A700);
    $dark-warn: mat.define-palette(mat.$red-palette);
    $dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

    @include mat.all-legacy-component-themes($dark-theme);
}

@import './radioockero';
@import './ttvn';
@import './lansochriksnytt';
@import './insyn';
@import './ybladet';
@import './ledarhunden';
